import { template as template_cf4549e2607c4f18b66d1d752ae7fd8c } from "@ember/template-compiler";
const FKText = template_cf4549e2607c4f18b66d1d752ae7fd8c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
