import { template as template_c17916ba79f04034a11d17520fdfdfe2 } from "@ember/template-compiler";
const FKLabel = template_c17916ba79f04034a11d17520fdfdfe2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
