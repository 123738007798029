import { template as template_7581ef0a6a0c4e709f6d2d10e718715a } from "@ember/template-compiler";
const FKControlMenuContainer = template_7581ef0a6a0c4e709f6d2d10e718715a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
