import { template as template_f414eaf28d8d469d8de537d570ffd86b } from "@ember/template-compiler";
const WelcomeHeader = template_f414eaf28d8d469d8de537d570ffd86b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
